import { ITrialToolingData } from "./TrialToolingSheetPlanningApproval"
import PrintIcon from "@mui/icons-material/Print"

import {
  // Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  // IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material"
import { miniLogo } from "../../../common/utilities"
import { useEffect, useState } from "react"
// import ThemedTextField from "../../../common/Resources/ThemedComponents/ThemedTextField"
import { PMDocumentGrid } from "./RepairInformant"
// import {
//   GET_CUSTOMERS,
//   GET_EMPLOYEE,
//   GET_MACHINES,
//   GET_PARTS
// } from "../../../common/Query/MasterQuery"
import { useMutation } from "@apollo/client"
// import { makeCustomerList } from "../../Master/Parts"
import ThemedButton from "../../../common/Resources/ThemedComponents/ThemedButton"
import moment from "moment"
// import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab"
// import DateAdapter from "@mui/lab/AdapterMoment"
// import DeleteIcon from "@mui/icons-material/Delete"
// import { IMachine } from "../../Master/Machine"
// import FormTextField from "../../../common/Resources/ThemedComponents/Maintenance/FormTextField"
// import { autocompleteRenderTags } from "../../../common/Resources/ThemedComponents/Other/autocompleteRenderTags"
// import ThemedLoadingButton from "../../../common/Resources/ThemedComponents/ThemedLoadingButton"
import { TRIAL_TOOLING_SHEET_UPDATE_APPROVAL } from "../../../common/Mutation/engineerMutation"
import swal from "sweetalert"
import { user } from "../../../common/MainApp"

interface ITooling {
  die: string
  size: string
  amount: number
  line: string
  machineCode: string
  [key: string]: number | string
}

const toolNameData: { key: string; name: string }[] = [
  { key: "newTool", name: "แม่พิมพ์ใหม่ NEW TOOLING" },
  { key: "modifyTool", name: "แม่พิมพ์แก้ไข MODIFY TOOLING" },
  { key: "supplyTool", name: "แม่พิมพ์จากลูกค้า SUPPLY TOOLING" },
  { key: "changeMachine", name: "เปลี่ยนเครื่องจักร CHANGE MACHINE" },
  { key: "changeOperator", name: "เปลี่ยนพนักงาน CHANGE OPERATOR" },
  { key: "changeMaterial", name: "เปลี่ยนวัตถุดิบ CHANGE MATERIAL" },
  {
    key: "changeSupplierMaterial",
    name: "เปลี่ยนผู้ส่งมอบวัตถุดิบ CHANGE SUPPLIER MATERIAL"
  },
  { key: "changePlant", name: "เปลี่ยนสถานที่ CHANGE PLANT" }
]

const StyledTableRow = styled(TableRow)(() => ({
  "td, th": {
    backgroundColor: "white",
    color: "black",
    padding: "5px"
  }
}))

const StyledTableCell = styled(TableCell)(() => ({
  blackGroundColor: "white",
  color: "black",
  textAlign: "center",
  padding: 0,
  fontSize: "16px",
  border: "solid 1px black"
}))

export const TrialToolingPaper: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props

  useEffect(() => {

    setPartName(filteredData?.partName)
    setPartNo(filteredData?.partNo)
    setCustomer(filteredData?.customer)
    setFactory(filteredData?.factory)
    setTrialDate(filteredData?.trialDate ? filteredData.trialDate : trialDate)
    setControlNo(filteredData?.controlNo)
    setTargetTrialEvent(filteredData?.targetTrialEvent)
    setCycleTime(filteredData?.cycleTime)
    setCapacity(filteredData?.capacity)
    setInternalDefect(filteredData?.internalDefect)
    setOperatorSkill(filteredData?.operatorSkill)
    setCpk(filteredData?.cpk)
    setReworkRatio(filteredData?.reworkRatio)


    setSelectedTools(
      filteredData?.selectedTools ? JSON.parse(filteredData.selectedTools) : {}
    )
    setIsOtherSelected(
      filteredData?.selectedTools
        ? JSON.parse(filteredData.selectedTools).other || false
        : false
    )
    setOtherToolName(
      filteredData?.selectedTools
        ? JSON.parse(filteredData.selectedTools).other || ""
        : ""
    )
    setToolings(
      filteredData?.toolingData
        ? JSON.parse(filteredData.toolingData)
        : toolingData
    )

    setPJPlanDate(
      filteredData?.pJPlanDate
        ? moment(filteredData.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : pJPlanDate
    )
    setPJStartTime(filteredData?.pJStartTime ? parseTimeStringToDate(filteredData?.pJStartTime) : pJStartTime)
    setPJEndTime(filteredData?.pJEndTime ? parseTimeStringToDate(filteredData?.pJEndTime) : pJEndTime)
    setPJSignature(filteredData?.pJSignature || "")
    setPJMembersTrial(
      filteredData?.pJMembersTrial
        ? JSON.parse(filteredData.pJMembersTrial)
        : pJMembersTrial
    )

    setPLPlanDate(
      filteredData?.pLPlanDate
        ? moment(filteredData.pLPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        : moment(filteredData?.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
    )
    setPLStartTime(
      filteredData?.pLStartTime
        ? parseTimeStringToDate(filteredData?.pLStartTime)
        : pLStartTime
    )
    setPLEndTime(
      filteredData?.pLEndTime
        ? parseTimeStringToDate(filteredData?.pLEndTime)
        : pLEndTime
    )
    setPLSignature(filteredData?.pLSignature || "")
    setPLMembersTrial(
      filteredData?.pLMembersTrial
        ? JSON.parse(filteredData.pLMembersTrial)
        : pLMembersTrial
    )

    setENGSignature(filteredData?.eNGSignature || "")
    setENGMembersTrial(
      filteredData?.eNGMembersTrial
        ? JSON.parse(filteredData.eNGMembersTrial)
        : eNGMembersTrial
    )

    setPD1Signature(filteredData?.pD1Signature || "")
    setPD1MembersTrial(
      filteredData?.pD1MembersTrial
        ? JSON.parse(filteredData.pD1MembersTrial)
        : pD1MembersTrial
    )

    setPD2Signature(filteredData?.pD2Signature || "")
    setPD2MembersTrial(
      filteredData?.pD2MembersTrial
        ? JSON.parse(filteredData.pD2MembersTrial)
        : pD2MembersTrial
    )

    setPDSignature(filteredData?.pDSignature || "")
    setPDMembersTrial(
      filteredData?.pDMembersTrial
        ? JSON.parse(filteredData.pDMembersTrial)
        : pDMembersTrial
    )

    setPTSignature(filteredData?.pTSignature || "")
    setPTMembersTrial(
      filteredData?.pTMembersTrial
        ? JSON.parse(filteredData.pTMembersTrial)
        : pTMembersTrial
    )

    setQASignature(filteredData?.qASignature || "")
    setQAMembersTrial(
      filteredData?.qAMembersTrial
        ? JSON.parse(filteredData.qAMembersTrial)
        : qAMembersTrial
    )

    setMTSignature(filteredData?.mTSignature || "")
    setMTMembersTrial(
      filteredData?.mTMembersTrial
        ? JSON.parse(filteredData.mTMembersTrial)
        : mTMembersTrial
    )

    setFmSignature(filteredData?.fmSignature || "")
    setEngineerHead1Signature(filteredData?.engineerHead1Signature || "")
    setEngineerHead2Signature(filteredData?.engineerHead2Signature || "")

    setFmSignatureTime(
      filteredData?.fmSignatureTime ? filteredData.fmSignatureTime : ""
    )

    setEngineerHead1SignatureTime(
      filteredData?.engineerHead1SignatureTime
        ? filteredData.engineerHead1SignatureTime
        : ""
    )
    setEngineerHead2SignatureTime(
      filteredData?.engineerHead2SignatureTime
        ? filteredData.engineerHead2SignatureTime
        : ""
    )
  }, [filteredData])

  function parseAndFormatArray(data: string | undefined): string {
    if (!data) return "" // Handle undefined case

    try {
      const array = JSON.parse(data) as string[] // Parse the string into an array
      return Array.isArray(array) ? array.join(", ") : "" // Ensure it's an array
    } catch (error) {
      console.error("Invalid JSON:", error)
      return "" // Return an empty string if parsing fails
    }
  }

  const theme = useTheme()
  const [partName, setPartName] = useState<string | undefined>("")
  const [partNo, setPartNo] = useState<string | undefined>("")
  const [customer, setCustomer] = useState<string | undefined>("")
  const [factory, setFactory] = useState<string | undefined>("")
  const [trialDate, setTrialDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )
  const [controlNo, setControlNo] = useState<string | undefined>("")
  // const [revisionNo, setRevisionNo] = useState<string>("")

  const [targetTrialEvent, setTargetTrialEvent] = useState<string | undefined>("")
  const [cycleTime, setCycleTime] = useState<number | undefined>(0)
  const [capacity, setCapacity] = useState<number | undefined>(0)
  const [internalDefect, setInternalDefect] = useState<number | undefined>(0)
  const [operatorSkill, setOperatorSkill] = useState<number | undefined>(0)
  const [cpk, setCpk] = useState<number | undefined>(0)
  const [reworkRatio, setReworkRatio] = useState<number | undefined>(0)

  const [selectedTools, setSelectedTools] = useState<Record<string, string>>({})

  // const { data: getParts } = useQuery(GET_PARTS)
  // let partNameList: string[] = []
  // let partNoList: string[] = []
  // if (getParts) {
  //   partNoList = Array.from(
  //     new Set(getParts.parts.map((part: { partNo: string }) => part.partNo))
  //   )
  //   partNameList = Array.from(
  //     new Set(getParts.parts.map((part: { partName: string }) => part.partName))
  //   )
  // }
  // const customerQuery = useQuery(GET_CUSTOMERS)
  // const customerList = useMemo(
  //   () => makeCustomerList(customerQuery),
  //   [customerQuery]
  // )


  const toolingData = [
    { die: "", size: "", amount: 0, line: "", machineCode: "" }
  ]

  // const handleCarFormSubmitTooling = (data: ITooling[]) => {
  //   setToolingData(data)
  // }

  const [material, setMaterial] = useState<string>("")
  const [plating, setPlating] = useState<string>("")
  const [trialNo, setTrialNo] = useState<string>("")
  const [quantity, setQuantity] = useState<number>(0)
  const [jobNo, setJobNo] = useState<string>("")

  const [pJPlanDate, setPJPlanDate] = useState<string>(
    moment(new Date()).format("YYYY-MM-DD")
  )

  const parseTimeStringToDate = (timeString?: string): Date | null => {
    if (!timeString) return null
    const [hours, minutes] = timeString.split(":").map(Number)
    const date = new Date()
    date.setHours(hours, minutes, 0, 0)
    return date
  }

  const [pJStartTime, setPJStartTime] = useState<Date | null>(null)
  const [pJEndTime, setPJEndTime] = useState<Date | null>(null)
  const [pJSignature, setPJSignature] = useState<string>("")
  const [pJMembersTrial, setPJMembersTrial] = useState<string[]>([])

  const [pLPlanDate, setPLPlanDate] = useState<string>(
    moment(filteredData?.pJPlanDate, "YYYY-MM-DD").format("YYYY-MM-DD")
  )

  const [pLStartTime, setPLStartTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.pJStartTime)
  )
  const [pLEndTime, setPLEndTime] = useState<Date | null>(
    parseTimeStringToDate(filteredData?.pJEndTime)
  )

  const displayTimeToDivision = (
    start?: string,
    end?: string,
    signature?: string
  ): string => {
    if (!signature) return ""
    return `${start || ""} - ${end || ""} น.`
  }

  const [pLSignature, setPLSignature] = useState<string>("")
  const [pLMembersTrial, setPLMembersTrial] = useState<string[]>([])

  const [eNGSignature, setENGSignature] = useState<string>("")
  const [eNGMembersTrial, setENGMembersTrial] = useState<string[]>([])

  const [pD1Signature, setPD1Signature] = useState<string>("")
  const [pD1MembersTrial, setPD1MembersTrial] = useState<string[]>([])

  const [pD2Signature, setPD2Signature] = useState<string>("")
  const [pD2MembersTrial, setPD2MembersTrial] = useState<string[]>([])

  const [pDSignature, setPDSignature] = useState<string>("")
  const [pDMembersTrial, setPDMembersTrial] = useState<string[]>([])

  const [pTSignature, setPTSignature] = useState<string>("")
  const [pTMembersTrial, setPTMembersTrial] = useState<string[]>([])

  const [qASignature, setQASignature] = useState<string>("")
  const [qAMembersTrial, setQAMembersTrial] = useState<string[]>([])

  const [mTSignature, setMTSignature] = useState<string>("")
  const [mTMembersTrial, setMTMembersTrial] = useState<string[]>([])

  // const { data: getEmployee } = useQuery(GET_EMPLOYEE)
  // const listEmployees: string[] =
  //   Array.from(
  //     new Set(
  //       getEmployee?.employee.map(
  //         ({ firstName }: { firstName: string }) => firstName
  //       )
  //     )
  //   ) || []

  // const pJSignatureTime: string = moment(new Date()).format("YYYY-MM-DD")
  const [fmSignature, setFmSignature] = useState<string>("")
  const [engineerHead1Signature, setEngineerHead1Signature] =
    useState<string>("")
  const [engineerHead2Signature, setEngineerHead2Signature] =
    useState<string>("")

  const [fmSignatureTime, setFmSignatureTime] = useState<string>("")
  const [engineerHead1SignatureTime, setEngineerHead1SignatureTime] =
    useState<string>("")
  const [engineerHead2SignatureTime, setEngineerHead2SignatureTime] =
    useState<string>("")


  // const [createTrialToolingSheet, { loading: loadingCreateTrialToolingSheet }] =
  //   useMutation(TRIAL_TOOLING_SHEET_CREATE)

  // const checkInput = async () => {
  //   if (
  //     !partName ||
  //     !partNo ||
  //     !customer ||
  //     !factory ||
  //     !trialDate ||
  //     !controlNo ||
  //     !revisionNo ||
  //     !revisionNo ||
  //     Object.keys(selectedTools).length === 0 ||
  //     toolingData.length < 1 ||
  //     !material ||
  //     !plating ||
  //     !trialNo ||
  //     !quantity ||
  //     !jobNo ||
  //     !pJSignature ||
  //     pJMembersTrial.length < 1
  //   ) {
  //     swal("Error", "กรุณากรอกข้อมูลให้ครบ", "warning")
  //     return false
  //   }

  //   if (
  //     targetTrialEvent ||
  //     cycleTime ||
  //     capacity ||
  //     internalDefect ||
  //     operatorSkill ||
  //     cpk ||
  //     reworkRatio
  //   ) {
  //     if (
  //       !targetTrialEvent ||
  //       !cycleTime ||
  //       !capacity ||
  //       !internalDefect ||
  //       !operatorSkill ||
  //       !cpk ||
  //       !reworkRatio
  //     ) {
  //       swal("Error", "กรอกข้อมูล TARGET TRIAL", "warning")
  //       return false
  //     }
  //   }

  //   if (Object.keys(selectedTools).length === 0) {
  //     swal("Error", "ไม่ได้ติ๊กเลือกสาเหตุ", "warning")
  //     return false
  //   }

  //   if (toolingData.length > 0) {
  //     for (const obj of toolingData) {
  //       for (const key in obj) {
  //         if (obj[key] === "" || obj[key] === 0) {
  //           swal("Error", "ระบุ Tooling Name ไม่ครบ", "warning")
  //           return false
  //         }
  //       }
  //     }
  //   }
  //   return true
  // }

  const checkDivisionSign = async () => {
    if (!eNGSignature && !pD1Signature && !pD2Signature && !pDSignature && !pTSignature && !qASignature && !mTSignature) {
      swal("Error", "ไม่สามารถ Approve ได้, ยังไม่มีแผนกใดๆลงนามและลงชื่อผู้ร่วมทดสอบ", "warning")
      return false
    }
    return true
  }

  // function checkEditedMember(arr1: string[], arr2: string[]): boolean {
  //   if (arr1.length !== arr2.length) {
  //     return false
  //   }

  //   const sortedArr1 = [...arr1].sort()
  //   const sortedArr2 = [...arr2].sort()

  //   for (let i = 0; i < sortedArr1.length; i++) {
  //     if (sortedArr1[i] !== sortedArr2[i]) {
  //       return false
  //     }
  //   }

  //   return true
  // }

  // const checkPlannerEditTimeSignatureAndMember = async () => {
  //   if (
  //     pLPlanDate !== filteredData?.pLPlanDate ||
  //     moment(pLStartTime).format("HH:mm") !== filteredData?.pLStartTime ||
  //     moment(pLEndTime).format("HH:mm") !== filteredData?.pLEndTime ||
  //     pLSignature !== filteredData?.pLSignature ||
  //     !checkEditedMember(
  //       pLMembersTrial,
  //       JSON.parse(filteredData.pLMembersTrial)
  //     )
  //   ) {
  //     if (
  //       filteredData?.engineerHead1Signature ||
  //       filteredData?.engineerHead2Signature
  //     ) {
  //       swal(
  //         "Error",
  //         "ไม่สามารถแก้ไขเวลา, ลายเซ็น หรือ สมาชิกได้ ได้เนื่องจากทางหัวหน้า Project Engineer Approval เอกสารแล้ว",
  //         "warning"
  //       )
  //       return false
  //     }
  //   }
  //   return true
  // }

  // const checkDivisionEditSignatureAndMemberAfterFmSign = async () => {
  //   if (filteredData?.fmSignature) {
  //     if (
  //       engineerHead1Signature !== filteredData?.engineerHead1Signature ||
  //       engineerHead2Signature !== filteredData?.engineerHead2Signature ||
  //       eNGSignature !== filteredData?.eNGSignature ||
  //       !checkEditedMember(
  //         eNGMembersTrial,
  //         JSON.parse(filteredData.eNGMembersTrial)
  //       ) ||
  //       pD1Signature !== filteredData?.pD1Signature ||
  //       !checkEditedMember(
  //         pD1MembersTrial,
  //         JSON.parse(filteredData.pD1MembersTrial)
  //       ) ||
  //       pD2Signature !== filteredData?.pD2Signature ||
  //       !checkEditedMember(
  //         pD2MembersTrial,
  //         JSON.parse(filteredData.pD2MembersTrial)
  //       ) ||
  //       pDSignature !== filteredData?.pDSignature ||
  //       !checkEditedMember(
  //         pDMembersTrial,
  //         JSON.parse(filteredData.pDMembersTrial)
  //       ) ||
  //       pTSignature !== filteredData?.pTSignature ||
  //       !checkEditedMember(
  //         pTMembersTrial,
  //         JSON.parse(filteredData.pTMembersTrial)
  //       ) ||
  //       qASignature !== filteredData?.qASignature ||
  //       !checkEditedMember(
  //         qAMembersTrial,
  //         JSON.parse(filteredData.qAMembersTrial)
  //       ) ||
  //       mTSignature !== filteredData?.mTSignature ||
  //       !checkEditedMember(
  //         mTMembersTrial,
  //         JSON.parse(filteredData.mTMembersTrial)
  //       )
  //     ) {
  //       swal(
  //         "Error",
  //         "เนื่องจาก FM Approved แล้ว, ไม่สามารถแก้ไขลายเซ็นต์หรือสมาชิกได้",
  //         "warning"
  //       )
  //       return false
  //     }
  //   }
  //   return true
  // }

  const [
    updateTrialToolingSheetApproval

  ] = useMutation(TRIAL_TOOLING_SHEET_UPDATE_APPROVAL)

  const handleUpdate = async (signature: string, signatureTime: string) => {
    // if (!(await checkMemberInput())) return
    // if (!(await checkPlannerEditTimeSignatureAndMember())) return
    // if (!(await checkDivisionEditSignatureAndMemberAfterFmSign())) return


    try {
      await updateTrialToolingSheetApproval({
        variables: {
          inputTrialToolingApproval: {
            trialId: filteredData?.trialId,
            fmSignature: signature,
            fmSignatureTime: signatureTime,

            updatedAt: filteredData?.updatedAt,
            updatedBy: user.username,
            fromPage: "Engineer > Trial Tooling Page"
          }
        }
      })
      swal("Success", "บันทึกข้อมูลเรียบร้อย", "success").then(() => {
        window.location.reload();
      });
    } catch (error) {
      swal("Error", `${(error as Error).message}`, "warning")
    }
  }

  // const handleSendFmApprove = async () => {
  //   if (
  //     !eNGSignature &&
  //     !pD1Signature &&
  //     !pD2Signature &&
  //     !pDSignature &&
  //     !pTSignature &&
  //     !qASignature &&
  //     !mTSignature
  //   ) {
  //     swal(
  //       "Error",
  //       "ส่งคำขอไม่ได้เนื่องจากยังไม่มี Section Division ใดๆลงชื่อ",
  //       "warning"
  //     )
  //     return
  //   }

  //   try {
  //     await updateTrialToolingSheetApproval({
  //       variables: {
  //         inputTrialToolingApproval: {
  //           trialId: filteredData?.trialId,
  //           sendToFmToApprove: true
  //         }
  //       }
  //     })
  //     swal("Success", "ส่งคำขอ FM สำเร็จ", "success")
  //   } catch (error) {
  //     swal("Error", `${(error as Error).message}`, "warning")
  //   }
  // }

  // const handleCreate = async () => {
  //   if (!(await checkInput())) return

  //   const data = {
  //     partName,
  //     partNo,
  //     customer,
  //     factory: factory === "โรง 1" ? "1" : "2",
  //     trialDate,
  //     targetTrialEvent,
  //     controlNo,
  //     revisionNo,
  //     cycleTime,
  //     capacity,
  //     internalDefect,
  //     operatorSkill,
  //     cpk,
  //     reworkRatio,
  //     selectedTools: JSON.stringify(selectedTools),
  //     toolingData: JSON.stringify(toolingData),
  //     material,
  //     plating,
  //     trialNo,
  //     quantity,
  //     jobNo,
  //     pJPlanDate,
  //     pJStartTime: moment(pJStartTime).format("HH:mm"),
  //     pJEndTime: moment(pJEndTime).format("HH:mm"),
  //     pJSignature,
  //     pJMembersTrial: JSON.stringify(pJMembersTrial)
  //   }

  //   try {
  //     await createTrialToolingSheet({
  //       variables: {
  //         inputTrialTooling: data
  //       }
  //     })
  //     swal("Success", "บันทึกสำเร็จ", "success")
  //   } catch (error) {
  //     swal("Error", `${(error as Error).message}`, "warning")
  //   }
  // }

  // const checkUserRoles = (
  //   userRole: string,
  //   rolesToCheck: string[]
  // ): boolean => {
  //   const rolesArray = userRole.split(",")

  //   return rolesToCheck.every((role) => rolesArray.includes(role))
  // }

  // const userRole = {
  //   pj: user.position === "Leader" || user.role === "99",
  //   pl:
  //     user.position === "Manager" ||
  //     checkUserRoles(user.role, ["3"]) ||
  //     user.role === "99",
  //   eng:
  //     (user.position === "Manager" && checkUserRoles(user.role, ["7"])) ||
  //     user.role === "99",
  //   pd1:
  //     (user.position === "Manager" &&
  //       user.factory === "1" &&
  //       checkUserRoles(user.role, ["4"])) ||
  //     user.role === "99",
  //   pd2:
  //     (user.position === "Manager" &&
  //       user.factory === "2" &&
  //       checkUserRoles(user.role, ["4"])) ||
  //     user.role === "99",
  //   pd:
  //     (user.position === "Manager" && checkUserRoles(user.role, ["4"])) ||
  //     user.role === "99",
  //   pt:
  //     (user.position === "Manager" && checkUserRoles(user.role, ["15"])) ||
  //     user.role === "99",
  //   qa:
  //     (user.position === "Manager" && checkUserRoles(user.role, ["6"])) ||
  //     user.role === "99",
  //   mt:
  //     (user.position === "Manager" && checkUserRoles(user.role, ["12"])) ||
  //     user.role === "99",
  //   leader:
  //     user.position === "Manager" ||
  //     checkUserRoles(user.role, ["7"]) ||
  //     user.role === "99",
  //   fm: user.position === "FM" || user.role === "99"
  // }

  // const disableTextFieldIfNoPLSignature = {
  //   pl: !userRole.pl || !pJSignature,
  //   eng:
  //     !userRole.eng ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   pd1:
  //     !userRole.pd1 ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   pd2:
  //     !userRole.pd2 ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   pd:
  //     !userRole.pd ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   pt:
  //     !userRole.pt ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   qa:
  //     !userRole.qa ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   mt:
  //     !userRole.mt ||
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature,
  //   leader: !pLSignature || !userRole.leader,
  //   fm:
  //     !pLSignature ||
  //     !engineerHead1Signature ||
  //     !engineerHead2Signature ||
  //     !userRole.fm
  // }

  const [otherToolName, setOtherToolName] = useState<string>("")
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false)

  // const handleToolChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   key: string,
  //   name: string
  // ) => {
  //   const { checked } = event.target
  //   setSelectedTools((prev) => {
  //     const updated = { ...prev }
  //     if (checked) {
  //       updated[key] = name
  //     } else {
  //       delete updated[key]
  //     }
  //     setSelectedTools(updated)
  //     return updated
  //   })
  // }

  // const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked } = event.target
  //   setIsOtherSelected(checked)

  //   if (!checked) {
  //     setOtherToolName("")
  //     setSelectedTools((prev) => {
  //       const updated = { ...prev }
  //       delete updated["other"]
  //       setSelectedTools(updated)
  //       return updated
  //     })
  //   }
  // }

  // const handleOtherToolNameInput = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const value = event.target.value
  //   setOtherToolName(value)

  //   setSelectedTools((prev) => {
  //     const updated = { ...prev }
  //     if (value) {
  //       updated["other"] = value
  //     } else {
  //       delete updated["other"]
  //     }
  //     setSelectedTools(updated) // Send the updated tools back to App
  //     return updated
  //   })
  // }

  const [toolings, setToolings] = useState<ITooling[]>(toolingData)

  // const { data: getMachine } = useQuery(GET_MACHINES)
  // const machineQuery: IMachine[] = getMachine?.machines || []

  // const machineList = Array.from(
  //   new Set(machineQuery.map((machine: IMachine) => machine.code))
  // )

  // const getLineOptions = (code: string | number) => {
  //   const line = machineQuery
  //     .filter((e) => e.code === code)
  //     .map((e) => (e.line ? e.line : ""))
  //   return Array.from(new Set(line)) 
  // }


  // const getMachinePowerOptions = (code: string | number) => {
  //   const machinePower = machineQuery
  //     .filter((e) => e.code === code)
  //     .map((e) => (e.machinePower ? e.machinePower : ""))
  //   return Array.from(new Set(machinePower)) 
  // }

  // const handleChange = (
  //   index: number,
  //   field: keyof ITooling,
  //   value: string | number
  // ) => {
  //   const newToolings = [...toolings]
  //   newToolings[index][field] = value

  //   setToolings(newToolings)
  //   handleCarFormSubmitTooling(newToolings)
  // }

  // const addTool = () => {
  //   const newToolings = [
  //     ...toolings,
  //     { die: "", size: "", amount: 0, line: "", machineCode: "" }
  //   ]
  //   setToolings(newToolings)


  //   handleCarFormSubmitTooling(newToolings)
  // }

  // const deleteTool = (index: number) => {
  //   const newToolings = toolings.filter((_, i) => i !== index)
  //   setToolings(newToolings)
  //   handleCarFormSubmitTooling(newToolings)
  // }

  // const formatTime = (time: string | number) => {
  //   const date = new Date(time)
  //   return date.toLocaleTimeString([], {
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     hour12: false
  //   })
  // }

  const fmConfirm = async (phase: string) => {
    if (!(await checkDivisionSign())) return
    const isConfirmed = await swal({
      title: "ต้องการยืนยันใช่หรือไม่?",
      text: phase,
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    })
    if (isConfirmed) {
      const signature = user.employee;
      const signatureTime = moment(new Date()).format("YYYY-MM-DD");

      setFmSignature(signature);
      setFmSignatureTime(signatureTime);

      await handleUpdate(signature, signatureTime);
    } else return
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="repair-print-head">
        <Table sx={{ backgroundColor: "#FFFFFF", color: "#000000" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={4}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <img src={miniLogo} alt="BAE logo" width={"80px"} />
                  <Typography variant="h1" sx={{ fontSize: "20px" }}>
                    Brother Auto Parts & Engineering Co.,Ltd.
                  </Typography>
                </Box>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell colSpan={2}>
                <Typography variant="h2" sx={{ fontSize: "15px" }}>
                  TRIAL TOOLING SHEET (ใบขอทดลองผลิต)
                </Typography>
              </StyledTableCell>
              <StyledTableCell colSpan={2}>
                <Box>Code No.: F-PJ-01-29</Box>
                <Box sx={{ fontWeight: "100", fontSize: "14px" }}>
                  Effective: 16/09/2019 Rev 01 Issue 1
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <StyledTableRow>
              <StyledTableCell colSpan={4}>
                <Grid container>
                  <Grid
                    container
                    item
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid item>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part Name: (ชื่อชิ้นงาน)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          // onChange={(e) => setControlNo(e.target.value)}
                          value={filteredData?.partName || partName}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />

                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Part No: (เลขที่ชิ้นงาน)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          // onChange={(e) => setControlNo(e.target.value)}
                          value={partNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />

                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Customer:(ลูกค้า)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          // onChange={(e) => setControlNo(e.target.value)}
                          value={filteredData?.customer || customer}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />

                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Plant:(สถานที่ขอทดลอง)</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          // onChange={(e) => setControlNo(e.target.value)}
                          value={filteredData?.factory || factory}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />

                      </PMDocumentGrid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Date/วันที่ออกเอกสาร</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          // onChange={(e) => setControlNo(e.target.value)}
                          value={
                            filteredData?.trialDate
                              ?
                              filteredData.trialDate

                              : trialDate
                          }
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />


                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Control No./เลขที่เอกสาร</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          // onChange={(e) => setControlNo(e.target.value)}
                          value={filteredData?.controlNo || controlNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      {/* <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Revision No./เอกสารแก้ไขฉบับที่</Typography>
                        <TextField
                          variant="standard"
                          type="text"
                          onChange={(e) => setRevisionNo(e.target.value)}
                          value={filteredData?.revisionNo || revisionNo}
                          sx={{
                            width: 300,
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid> */}
                    </Grid>
                    {/* <Grid item>
                      <Box>
                        <img src={miniLogo} alt="BAE logo" width={"80px"} />
                      </Box>
                    </Grid> */}
                    <Grid
                      item
                      sx={{
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        p: "20px"
                      }}
                    >
                      <Grid item>
                        <Typography>TARGET TRIAL</Typography>
                      </Grid>
                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>Event</Typography>
                        <TextField
                          fullWidth
                          type="text"
                          variant="standard"
                          // onChange={(e) => setTargetTrialEvent(e.target.value)}
                          value={
                            filteredData?.targetTrialEvent || targetTrialEvent
                          }
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>1. Cycle time :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          // onChange={(e) => setCycleTime(+e.target.value)}
                          value={filteredData?.cycleTime || cycleTime}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(Sec.)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>2. Capacity :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          // onChange={(e) => setCapacity(+e.target.value)}
                          value={filteredData?.capacity || capacity}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>3. Internal defect :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          // onChange={(e) => setInternalDefect(+e.target.value)}
                          value={filteredData?.internalDefect || internalDefect}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>4. Operator Skill :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          // onChange={(e) => setOperatorSkill(+e.target.value)}
                          value={filteredData?.operatorSkill || operatorSkill}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>5. Cpk :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          // onChange={(e) => setCpk(+e.target.value)}
                          value={filteredData?.cpk || cpk}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                      </PMDocumentGrid>

                      <PMDocumentGrid item justifyContent={"start"}>
                        <Typography>6. Rework Ratio :</Typography>
                        <TextField
                          type="number"
                          variant="standard"
                          // onChange={(e) => setReworkRatio(+e.target.value)}
                          value={filteredData?.reworkRatio || reworkRatio}
                          sx={{
                            input: {
                              color: "black",
                              textAlign: "center"
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "#666666"
                            },
                            "& .MuiInput-underline:hover:before": {
                              borderBottomColor: "#666666"
                            }
                          }}
                        />
                        <Typography>(%)</Typography>
                      </PMDocumentGrid>
                    </Grid>
                  </Grid>

                  {/* <Grid item sx={{ mt: "50px" }}>
                                        <CheckboxTooling
                                            optionsCol1={column1Options}
                                            optionsCol2={column2Options}
                                            optionsCol3={column3Options}
                                            onChange={handleCheckboxChange}
                                            otherLabel={column3Options[0]}
                                        />
                                    </Grid> */}
                  <Grid item sx={{ mt: "50px" }}>
                    <Box sx={{ padding: 2 }}>
                      <FormGroup>
                        <Grid container spacing={2}>
                          {toolNameData.map((tool) => (
                            <Grid item xs={4} key={tool.key} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // onChange={(e) =>
                                    //   handleToolChange(e, tool.key, tool.name)
                                    // }
                                    checked={!!selectedTools[tool.key]}
                                    sx={{
                                      color: 'black',
                                      '&.Mui-checked': {
                                        color: 'black',
                                      },
                                    }}
                                  />
                                }
                                label={tool.name}
                              />
                            </Grid>
                          ))}
                          <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  // onChange={handleOtherChange}
                                  checked={isOtherSelected}
                                  sx={{
                                    color: 'black',
                                    '&.Mui-checked': {
                                      color: 'black',
                                    },
                                  }}
                                />
                              }
                              label="Other"
                            />
                            <TextField
                              fullWidth
                              variant="standard"
                              disabled={!isOtherSelected}
                              value={otherToolName}
                              // onChange={handleOtherToolNameInput}
                              sx={{
                                input: { color: "black", textAlign: "center" },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </FormGroup>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      pt: "50px",
                      borderTop: "2px solid #000"
                    }}
                    xs={12}
                  >
                    <Grid container direction="column" spacing={3}>
                      {toolings.map((tooling, index) => (
                        <Grid item key={index} >
                          <PMDocumentGrid container justifyContent={"start"} spacing={2} sx={{ marginLeft: "10px" }}>
                            <Typography>
                              {index + 1}. TOOLING NAME :(ชื่อแม่พิมพ์)
                            </Typography>
                            <TextField
                              variant="standard"
                              // onChange={(e) =>
                              //   handleChange(index, "die", e.target.value || "")
                              // }
                              value={toolings[index].die}
                              sx={{
                                width: "200px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>M/C</Typography>

                            <Typography>Size(ขนาดเครื่องจักร)</Typography>
                            <TextField
                              variant="standard"
                              type="text"
                              // onChange={(e) => setControlNo(e.target.value)}
                              value={toolings[index].size}
                              sx={{
                                width: 200,
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />

                            <Typography sx={{ pr: "20px" }}>
                              Tons(ตัน)
                            </Typography>

                            <Typography>จำนวน</Typography>
                            <TextField
                              type="number"
                              variant="standard"
                              // onChange={(e) =>
                              //   handleChange(
                              //     index,
                              //     "amount",
                              //     +e.target.value || 0
                              //   )
                              // }
                              value={toolings[index].amount}
                              sx={{
                                width: "50px",
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />
                            <Typography sx={{ pr: "20px" }}>เครื่อง</Typography>

                            <Typography>Line</Typography>
                            <TextField
                              variant="standard"
                              type="text"
                              // onChange={(e) => setControlNo(e.target.value)}
                              value={toolings[index].line}
                              sx={{
                                width: 150,
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />

                            <Typography sx={{ pr: "20px" }}>No.</Typography>

                            <Typography>M/C (เลขที่เครื่องจักร)</Typography>
                            <TextField
                              variant="standard"
                              type="text"
                              // onChange={(e) => setControlNo(e.target.value)}
                              value={toolings[index].machineCode}
                              sx={{
                                width: 200,
                                input: {
                                  color: "black",
                                  textAlign: "center"
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "#666666"
                                },
                                "& .MuiInput-underline:hover:before": {
                                  borderBottomColor: "#666666"
                                }
                              }}
                            />


                            {/* <IconButton
                              onClick={() => deleteTool(index)}
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton> */}
                          </PMDocumentGrid>
                        </Grid>
                      ))}
                      {/* <Grid item>
                        <ThemedLoadingButton
                          variant="contained"
                          onClick={addTool}
                        >
                          เพิ่ม Tooling name
                        </ThemedLoadingButton>
                      </Grid> */}

                      <Grid
                        container
                        item
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "10px"
                        }}
                      >
                        <PMDocumentGrid item justifyContent={"start"} xs={6} sx={{ paddingLeft: "0px" }}>
                          <Typography>MATERIAL (วัตถุดิบ) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setMaterial(e.target.value)}
                            value={filteredData?.material || material}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>ผิวชุบ Plating Coating </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setPlating(e.target.value)}
                            value={filteredData?.plating || plating}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>

                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Trial No. (เลขที่ทดลอง) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setTrialNo(e.target.value)}
                            value={filteredData?.trialNo || trialNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>Q'ty (จำนวนชิ้น) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setQuantity(+e.target.value)}
                            value={filteredData?.quantity || quantity}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                          <Typography>Pcs. (ชิ้น)</Typography>
                        </PMDocumentGrid>
                        <PMDocumentGrid item justifyContent={"start"} xs={6}>
                          <Typography>JOB No.(เลขที่สั่งงาน) </Typography>
                          <TextField
                            type="text"
                            variant="standard"
                            onChange={(e) => setJobNo(e.target.value)}
                            value={filteredData?.jobNo || jobNo}
                            sx={{
                              input: {
                                color: "black",
                                textAlign: "center"
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "#666666"
                              },
                              "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "#666666"
                              }
                            }}
                          />
                        </PMDocumentGrid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    sx={{
                      mt: "50px"
                    }}
                    xs={12}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell rowSpan={2} colSpan={2}>
                            <Typography>ฝ่าย/แผนก</Typography>
                            <Typography>Department/Section Division</Typography>
                          </StyledTableCell>
                          <StyledTableCell colSpan={2}>
                            <Typography>
                              Trial Plan Date(วันที่ทดลอง)
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>Signature</Typography>
                            <Typography>ลงชื่อ</Typography>
                          </StyledTableCell>
                          <StyledTableCell rowSpan={2}>
                            <Typography>MEMBER TRIAL</Typography>
                            <Typography>รายชื่อผู้ร่วมทดลองการผลิต</Typography>
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          <StyledTableCell>Plan Date(วัน)</StyledTableCell>
                          <StyledTableCell>Plan Time(เวลา)</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell>วิศวกรโครงการ</StyledTableCell>
                          <StyledTableCell>PJ</StyledTableCell>
                          <StyledTableCell>{pLPlanDate}</StyledTableCell>
                          <StyledTableCell>
                            {filteredData?.pLStartTime} -{" "}
                            {filteredData?.pLEndTime}
                            {` น.`}
                          </StyledTableCell>
                          <StyledTableCell>{pJSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pJMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>วางแผน</StyledTableCell>
                          <StyledTableCell>PL</StyledTableCell>
                          <StyledTableCell>{pLPlanDate}</StyledTableCell>
                          <StyledTableCell>
                            {filteredData?.pLStartTime} -{" "}
                            {filteredData?.pLEndTime}
                            {` น.`}
                          </StyledTableCell>
                          <StyledTableCell>{pLSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pLMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>วิศวกรรม</StyledTableCell>
                          <StyledTableCell>ENG</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && eNGSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {eNGSignature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{eNGSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.eNGMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ผลิตโรง 1</StyledTableCell>
                          <StyledTableCell>PD 1</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && pD1Signature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pD1Signature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{pD1Signature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pD1MembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ผลิตโรง 2</StyledTableCell>
                          <StyledTableCell>PD 2</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && pD2Signature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pD2Signature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{pD2Signature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pD2MembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>
                            ประกอบ Spot/Wedling Assemble
                          </StyledTableCell>
                          <StyledTableCell>PD</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && pDSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pDSignature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{pDSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pDMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ชุบโลหะ</StyledTableCell>
                          <StyledTableCell>PT</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && pTSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {pTSignature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{pTSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.pTMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ประกันคุณภาพ</StyledTableCell>
                          <StyledTableCell>QA</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && qASignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {qASignature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{qASignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.qAMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>

                        <StyledTableRow>
                          <StyledTableCell>ซ่อมบำรุง</StyledTableCell>
                          <StyledTableCell>MT</StyledTableCell>
                          <StyledTableCell>
                            {pLPlanDate && pLSignature && mTSignature ? pLPlanDate : ""}
                          </StyledTableCell>
                          <StyledTableCell>
                            {mTSignature ? displayTimeToDivision(
                              filteredData?.pLStartTime,
                              filteredData?.pLEndTime,
                              pLSignature
                            ) : ""}
                          </StyledTableCell>
                          <StyledTableCell>{mTSignature}</StyledTableCell>
                          <StyledTableCell>
                            {parseAndFormatArray(filteredData?.mTMembersTrial)}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell>Approve by (ผู้อนุมัติ)</StyledTableCell>
              <StyledTableCell>Approve by (ผู้อนุมัติ)</StyledTableCell>
              <StyledTableCell>Check by (ผู้ตรวจสอบ)</StyledTableCell>
              <StyledTableCell>Prepare by (ผู้จัดทำ)</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ height: "50px" }}>
                {/* {fmSignature} */}

                {fmSignature ? fmSignature : <ThemedButton
                  sx={{ bgcolor: theme.palette.warning.main }}
                  variant="outlined"
                  onClick={() => {
                    fmConfirm(`อนุมัติเอกสารนี้โดย: ${user.employee}`)
                  }}
                >
                  Verify
                </ThemedButton>}

              </StyledTableCell>
              <StyledTableCell>{engineerHead1Signature}</StyledTableCell>
              <StyledTableCell>{engineerHead2Signature}</StyledTableCell>
              <StyledTableCell>{pJSignature}</StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell sx={{ height: "25px" }}>
                {fmSignatureTime}
              </StyledTableCell>
              <StyledTableCell>{engineerHead1SignatureTime}</StyledTableCell>
              <StyledTableCell>{engineerHead2SignatureTime}</StyledTableCell>
              <StyledTableCell>
                {filteredData?.pJSignature ? filteredData?.pJSignatureTime : ""}
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>FM ผู้จัดการโรงงาน</StyledTableCell>
              <StyledTableCell colSpan={3}>
                Reqeust by (ผู้ร้องขอ) Project Engineer
              </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </ThemeProvider>
  )
}

interface IProp {
  filteredData?: ITrialToolingData
}
const TrialToolingSheetFmApproval: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props
  return (
    <>
      <div className="hidden-print">
        <ThemedButton
          variant="outlined"
          aria-label="print"
          endIcon={<PrintIcon />}
          style={{
            marginBottom: "5px"
          }}
          onClick={() => window.print()}
        >
          Print
        </ThemedButton>
      </div>
      <div className="repair-print-head">
        <TrialToolingPaper filteredData={filteredData} />
      </div>
    </>
  )
}

export default TrialToolingSheetFmApproval

import { ITrialToolingData } from "./TrialToolingSheetPlanningApproval"
import TrialToolingPaper from "./TrialToolingSheetPlanningApproval"

interface IProp {
  filteredData?: ITrialToolingData
}
const TrialToolingSheetDivisionApproval: React.FC<IProp> = (props: IProp) => {
  const { filteredData } = props
  return (
    <>
      <TrialToolingPaper filteredData={filteredData} />
    </>
  )
}

export default TrialToolingSheetDivisionApproval
